<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewContent"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New Content</span>
          </div>

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="in_use">Status</vs-th>
          <!-- <vs-th sort-key="source">Source Site</vs-th> -->
          <vs-th sort-key="source">Source</vs-th>
          <vs-th sort-key="status_code">Status Code</vs-th>
          <!-- <vs-th sort-key="source">Target Site</vs-th> -->
          <vs-th sort-key="target">Target</vs-th>
          <vs-th sort-key="expiry">Started at</vs-th>
          <vs-th sort-key="expiry">Expired at</vs-th>
          <vs-th sort-key="priority">Priority</vs-th>
          <vs-th sort-key="active">Active</vs-th>
          <vs-th sort-key="updated_at">Updated At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)" v-if="tr.in_use=='USED'"><vs-button 
                color="success" 
                class="disabled-btn"
                type="border">USED</vs-button></td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)" v-else><vs-button 
                color="danger" 
                class="disabled-btn"
                type="border">UNUSED</vs-button></td>
              <!-- <vs-td>{{ tr.source_site ? tr.source_site : "Not set" }}</vs-td> -->

              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="url">{{ tr.source }}</p>
                <a 
                  @click.stop=""
                  :href="previewUrl(tr.source)" 
                  target="_blank"><link-icon size="1.0x" /></a></td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.status_code }}</td>
              <!-- <vs-td>{{ tr.target_site ? tr.target_site : "Not set" }}</vs-td> -->
              <!-- <vs-td>{{ tr.target }} <a :href="tr.target"><link-icon size="1.0x" /></a></vs-td> -->
              
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="url">{{ tr.target }}</p>
              </td>
              <!-- <vs-td>{{ tr.status_code }}</vs-td> -->
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ tr.start_date ? publicDateFormat(tr.start_date) : "Not set" }}</p>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ tr.expiry ? publicDateFormat(tr.expiry) : "Not set" }}</p>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.priority }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.active ? "active" : "Inactive" }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority url">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <!-- <feather-icon
                    icon="CopyIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />-->
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <!-- <feather-icon
                    icon="MonitorIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="checkFollowRedirects(tr)"
                  /> -->
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
// import get from 'lodash/get'
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import useRedirectionUtil from '@/use/useRedirectionUtil'
import omit from 'lodash/omit'
import { LinkIcon } from 'vue-feather-icons'
import env from '@/env'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'SEOListView',
  components: {
    LinkIcon,
    LhDropdownPerPage,
    CustomTable
  },
  data() {
    return {
      websiteUrl: `${env.VUE_APP_WEBSITE_URL}`.replace(/\/$/, "")
    }
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'redirection')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useRedirectionUtil(ctx)

    crudFunction.routePrefix.value = 'redirection'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'redirection/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const resultDuplicate = await ctx.root.$store.dispatch(
        'redirection/addItem',
        omit(formData, ['id', 'revision_id']),
      )
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    const addNewContent = () => {
      ctx.root.$router.push({
        name: 'redirection-create',
      })
    }

    const previewUrl = (slug) => {
      const baseUrl = env.VUE_APP_WEBSITE_URL
      if (slug.indexOf('https://') > -1 || slug.indexOf('http://') > -1) {
        return slug
      }
      
      let replaceString = slug.replace('^', '')
      replaceString = `${baseUrl}${replaceString}`
      return replaceString.replace('$', '')
    }

    return {
      ...crudFunction,
      items,
      addNewContent,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      previewUrl,
      searchKeys: ['source', 'target']
    }
  },
  methods: {
    checkFollowRedirects(input) {
      console.log(input)
      return true
    }
  }
}
</script>

<style lang="scss">
  .disabled-btn {
    cursor: default;
    pointer-events: none;
  }
  .url {
    word-break: break-all;
  }
</style>
